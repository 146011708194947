import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { selectIsCompanySuperAdmin } from 'src/app/store/selectors/auth.selector';

@Injectable({
  providedIn: 'root',
})
export class CompanySuperAdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute
  ) {}

  canActivate() {
    return this.store.select(selectIsCompanySuperAdmin).pipe(
      map((value) => {
        if (value) {
          return true;
        }
        this.router.navigate(['app'], { relativeTo: this.activatedRoute });
        return false;
      })
    );
  }
}
