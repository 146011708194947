import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CountryService } from '../services/country.service';

@Pipe({
  name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {
  constructor(private countryService: CountryService) {}

  transform(value: any): Observable<string> {
    return this.countryService.getCountries().pipe(
      map((types) => {
        if (!value) {
          return '';
        }
        let i = types.findIndex(
          (type) => type.code.toLowerCase() === value.toLowerCase()
        );
        if (i < 0) {
          return value;
        }
        return types[i].label;
      })
    );
  }
}
