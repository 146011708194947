import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FakeMissingTranslationHandler,
  TranslateDefaultParser,
  TranslateFakeCompiler,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root',
})
export class MyCvTranslationService extends TranslateService {
  constructor(http: HttpClient, preferencesService: PreferencesService) {
    super(
      new TranslateStore(),
      new TranslateHttpLoader(
        http,
        'assets/i18n/my-cv.',
        `.json?v=${Math.random()}`
      ),
      new TranslateFakeCompiler(),
      new TranslateDefaultParser(),
      new FakeMissingTranslationHandler(),
      true,
      true,
      false,
      preferencesService.myCvLanguage.toLowerCase()
    );
  }
}
