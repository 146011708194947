export enum GroupTeamsSearchSortEnum {
  ALPHABETICAL_ASC = 'ALPHABETICAL_ASC',
  ALPHABETICAL_DESC = 'ALPHABETICAL_DESC',
}

export const GroupTeamsSearchSortOptions: {
  code: GroupTeamsSearchSortEnum;
  name: string;
}[] = [
  {
    code: GroupTeamsSearchSortEnum.ALPHABETICAL_ASC,
    name: 'manage-group-teams.name-a-to-z',
  },
  {
    code: GroupTeamsSearchSortEnum.ALPHABETICAL_DESC,
    name: 'manage-group-teams.name-z-to-a',
  },
];
