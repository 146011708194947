import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ToastService } from '../services/toast.service';

@Injectable()
export class NoInternetInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!window.navigator.onLine) {
      this.toastService.danger({
        title: this.translateService.instant('errors.no-internet.title'),
        text: this.translateService.instant('errors.no-internet.text'),
      });
    }
    return next.handle(request);
  }
}
