import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const alert = {
          title: this.translateService.instant('errors.500.title'),
          text: this.translateService.instant('errors.500.text'),
        };
        if (error.status === 0) {
          alert.title = this.translateService.instant(
            'errors.no-internet.title'
          );
          alert.text = this.translateService.instant('errors.no-internet.text');
        }
        if (
          (error.status === 0 || error.status >= 500) &&
          (!error.error?.cause || !error.error?.message)
        ) {
          this.toastService.danger(alert);
        }
        return throwError(error);
      })
    );
  }
}
