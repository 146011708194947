import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AddTeamMemberComponent } from './components/add-team-member/add-team-member.component';
import { AddToGroupItemComponent } from './components/add-to-group-item/add-to-group-item.component';
import { AddToGroupComponent } from './components/add-to-group/add-to-group.component';
import { CollapseMainMenuComponent } from './components/collapse-main-menu/collapse-main-menu.component';
import { CollapseResponsiveMenuComponent } from './components/collapse-responsive-menu/collapse-responsive-menu.component';
import { ExportComponent } from './components/export/export.component';
import { GlobeIconSiteLanguageSelectorComponent } from './components/globe-icon-site-language-selector/globe-icon-site-language-selector.component';
import { InvalidFeedbackComponent } from './components/invalid-feedback/invalid-feedback.component';
import { SearchableDropdownComponent } from './components/searchable-dropdown/searchable-dropdown.component';
import { SelectGroupItemComponent } from './components/select-group-item/select-group-item.component';
import { SelectGroupComponent } from './components/select-group/select-group.component';
import { SiteLogoComponent } from './components/site-logo/site-logo.component';
import { ToastComponent } from './components/toast/toast.component';
import { YearMonthPickerComponent } from './components/year-month-picker/year-month-picker.component';
import { AutoResizeTextareaDirective } from './directives/auto-resize-textarea.directive';
import { ButtonRippleEffectDirective } from './directives/button-ripple-effect.directive';
import { DatePickerDirective } from './directives/date-picker.directive';
import { DisableAutocompleteDirective } from './directives/disable-autocomplete.directive';
import { DisableEnterDirective } from './directives/disable-enter.directive';
import { EscapePressDirective } from './directives/escape-press.directive';
import { FilterDropdownDirective } from './directives/filter-dropdown.directive';
import { HeightDirective } from './directives/height.directive';
import { InputClearDirective } from './directives/input-clear.directive';
import { InputFocusDirective } from './directives/input-focus.directive';
import { InputInvalidFeedbackDirective } from './directives/input-invalid-feedback.directive';
import { NavbarBlurDirective } from './directives/navbar-blur.directive';
import { NoEnterSubmitDirective } from './directives/no-enter-submit.directive';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { RangeSliderDirective } from './directives/range-slider.directive';
import { SelectDropdownDirective } from './directives/select-dropdown.directive';
import { TextareaHeightHandlerDirective } from './directives/textarea-height-handler.directive';
import { TippyDirective } from './directives/tippy.directive';
import { CompanySuperAdminGuard } from './guards/company-super-admin.guard';
import { IsAuthenticationGuard } from './guards/is-authenticated.guard';
import { LoginBypassGuard } from './guards/login-bypass.guard';
import { PdfAuthenticatedGuard } from './guards/pdf-authenticated.guard';
import { AuthorizationErrorInterceptor } from './interceptors/authorization-error.interceptor';
import { AuthorizationHeaderInterceptor } from './interceptors/authorization-header.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { NoInternetInterceptor } from './interceptors/no-internet.interceptor';
import { PdfAuthorizationHeaderInterceptor } from './interceptors/pdf-authorization-header.interceptor';
import { SelectedCompanyHeaderInterceptor } from './interceptors/selected-company-header.interceptor';
import { SelectedGroupHeaderInterceptor } from './interceptors/selected-group-header.interceptor';
import { ClickableLinkPipe } from './pipes/clickable-link.pipe';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { EducationGradePipe } from './pipes/education-grade.pipe';
import { EmailBreakPipe } from './pipes/email-break.pipe';
import { InitialsPipe } from './pipes/initials.pipe';
import { LanguageNamePipe } from './pipes/language-name.pipe';
import { EmailTypePipe } from './pipes/mail-type.pipe';
import { PhoneTypePipe } from './pipes/phone-type.pipe';
import { TextBreakPipe } from './pipes/text-break.pipe';
import { TranslateMyCvPipe } from './pipes/translate-my-cv.pipe';
import { MyCvTranslationService } from './services/my-cv-translation.service';
import { MergeHtmlContentDirective } from './directives/merge-html-content.directive';

@NgModule({
  declarations: [
    InputFocusDirective,
    ButtonRippleEffectDirective,
    InputInvalidFeedbackDirective,
    GlobeIconSiteLanguageSelectorComponent,
    InvalidFeedbackComponent,
    SiteLogoComponent,
    CollapseMainMenuComponent,
    SelectDropdownDirective,
    NavbarBlurDirective,
    FilterDropdownDirective,
    CollapseResponsiveMenuComponent,
    InputClearDirective,
    SearchableDropdownComponent,
    PhoneTypePipe,
    EmailTypePipe,
    TippyDirective,
    DisableAutocompleteDirective,
    CountryNamePipe,
    LanguageNamePipe,
    EducationGradePipe,
    EscapePressDirective,
    OutsideClickDirective,
    ClickableLinkPipe,
    ToastComponent,
    AddToGroupComponent,
    AddToGroupItemComponent,
    DatePickerDirective,
    TextareaHeightHandlerDirective,
    InitialsPipe,
    TranslateMyCvPipe,
    DisableEnterDirective,
    AutoResizeTextareaDirective,
    YearMonthPickerComponent,
    NoEnterSubmitDirective,
    SelectGroupComponent,
    SelectGroupItemComponent,
    ExportComponent,
    AddTeamMemberComponent,
    EmailBreakPipe,
    TextBreakPipe,
    RangeSliderDirective,
    HeightDirective,
    MergeHtmlContentDirective,
  ],
  exports: [
    InputFocusDirective,
    ButtonRippleEffectDirective,
    InputInvalidFeedbackDirective,
    InvalidFeedbackComponent,
    GlobeIconSiteLanguageSelectorComponent,
    SiteLogoComponent,
    CollapseMainMenuComponent,
    SelectDropdownDirective,
    NavbarBlurDirective,
    FilterDropdownDirective,
    CollapseResponsiveMenuComponent,
    InputClearDirective,
    SearchableDropdownComponent,
    PhoneTypePipe,
    EmailTypePipe,
    TippyDirective,
    DisableAutocompleteDirective,
    CountryNamePipe,
    LanguageNamePipe,
    EducationGradePipe,
    EscapePressDirective,
    OutsideClickDirective,
    ClickableLinkPipe,
    ToastComponent,
    AddToGroupComponent,
    DatePickerDirective,
    TextareaHeightHandlerDirective,
    InitialsPipe,
    TranslateMyCvPipe,
    DisableEnterDirective,
    AutoResizeTextareaDirective,
    YearMonthPickerComponent,
    NoEnterSubmitDirective,
    SelectGroupComponent,
    ExportComponent,
    AddTeamMemberComponent,
    EmailBreakPipe,
    TextBreakPipe,
    RangeSliderDirective,
    HeightDirective,
    MergeHtmlContentDirective,
  ],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoInternetInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PdfAuthorizationHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SelectedCompanyHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SelectedGroupHeaderInterceptor,
      multi: true,
    },
    IsAuthenticationGuard,
    PdfAuthenticatedGuard,
    LoginBypassGuard,
    CompanySuperAdminGuard,
    { provide: 'myCvTranslator', useExisting: MyCvTranslationService },
    CountryNamePipe,
  ],
})
export class SharedModule {}
