import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class SelectedGroupHeaderInterceptor implements HttpInterceptor {
  constructor(private activatedRoute: ActivatedRoute) {}
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const paths = ['/v1/candidates', '/interviews'];
    const ignoreSlugs = ['details', 'team', 'add', 'edit'];
    if (paths.some((v) => request.url.includes(v))) {
      let slug = '';
      try {
        const groupSlugPostion = 5;
        slug = location.href.split('/')[groupSlugPostion].split('?')[0];
      } catch (error) {}
      if (ignoreSlugs.includes(slug)) slug = '';
      if (!slug && this.activatedRoute.snapshot.queryParams['groupSlug']) {
        slug = this.activatedRoute.snapshot.queryParams['groupSlug'];
      }
      if (!slug) {
        if (location.pathname.includes('/candidates')) {
          slug = 'all-candidates';
        }
        if (location.pathname.includes('/contacts')) {
          slug = 'all-contacts';
        }
      }
      if (slug) {
        const clone = request.clone({
          headers: request.headers.set('x-group-slug', slug),
        });
        return next.handle(clone);
      }
    }
    return next.handle(request);
  }
}
