export enum CandidatesSearchSortEnum {

  ALPHABETICAL_ASC = 'ALPHABETICAL_ASC',
  ALPHABETICAL_DESC = 'ALPHABETICAL_DESC',
  NEWEST_ASC = 'NEWEST_ASC',
  NEWEST_DESC = 'NEWEST_DESC',

}

export const CandidateSearchSortOptions: { code: CandidatesSearchSortEnum, name: string }[] = [
  {code: CandidatesSearchSortEnum.NEWEST_ASC, name: "candidates.list.newest-top"},
  {code: CandidatesSearchSortEnum.NEWEST_DESC, name: "candidates.list.oldest-top"},
  {code: CandidatesSearchSortEnum.ALPHABETICAL_ASC, name: "candidates.list.name-a-to-z"},
  {code: CandidatesSearchSortEnum.ALPHABETICAL_DESC, name: "candidates.list.name-z-to-a"},
]
