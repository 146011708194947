import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { IsAuthenticationGuard } from './shared/guards/is-authenticated.guard';
import { LoginBypassGuard } from './shared/guards/login-bypass.guard';
import { PdfAuthenticatedGuard } from './shared/guards/pdf-authenticated.guard';

const routes: Routes = [
  {
    path: 'log-in',
    canActivate: [LoginBypassGuard],
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  // {
  //   path: 'sign-up',
  //   canActivate: [LoginBypassGuard],
  //   loadChildren: () =>
  //     import('./signup/signup.module').then((m) => m.SignupModule),
  // },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'reset-password/:hash',
    loadChildren: () =>
      import('./recover-password/recover-password.module').then(
        (m) => m.RecoverPasswordModule
      ),
  },
  {
    path: 'app',
    canActivate: [IsAuthenticationGuard],
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'pdf',
    canActivate: [PdfAuthenticatedGuard],
    loadChildren: () => import('./pdf/pdf.module').then((m) => m.PdfModule),
  },
  {
    path: 'activate-account',
    canActivate: [IsAuthenticationGuard],
    loadChildren: () =>
      import('./activate-account/activate-account.module').then(
        (m) => m.ActivateAccountModule
      ),
  },
  {
    path: 'confirm-user-email',
    canActivate: [IsAuthenticationGuard],
    loadChildren: () =>
      import('./confirm-user-email/confirm-user-email.module').then(
        (m) => m.ConfirmUserEmailModule
      ),
  },
  {
    path: 'confirm-company-email',
    canActivate: [IsAuthenticationGuard],
    loadChildren: () =>
      import('./confirm-company-email/confirm-company-email.module').then(
        (m) => m.ConfirmCompanyEmailModule
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'log-in',
  },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
