import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {LocalStorageService} from "./local-storage.service";
import {CountryModel} from "../model/country.model";

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private readonly metadata_api: string;

  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    this.metadata_api = `${environment.metadata_api}`;
  }

  public getCountries(): Observable<CountryModel[]> {
    if (!!this.localStorageService.countries) {
      return of(this.localStorageService.countries)
    }
    return this.httpClient.get<CountryModel[]>(`${this.metadata_api}/api/v1/metadata/countries`).pipe(
      map((result: any[]) => {
        const resultset: CountryModel[] = [];
        result.forEach(row => resultset.push(Object.assign(new CountryModel(), row)));
        this.localStorageService.countries = resultset;
        return resultset;
      })
    )
  }

}
