import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PdfAuthenticatedGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}
  canActivate() {
    if (this.authenticationService.hasAuthenticatedPdfUser()) {
      return true;
    }
    this.router.navigate(['log-in'], { relativeTo: this.activatedRoute });
    return false;
  }
}
