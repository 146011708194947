import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
})
export class ToastComponent implements AfterViewInit {
  @ViewChild('successToast') successToast!: ElementRef;
  @ViewChild('infoToast') infoToast!: ElementRef;
  @ViewChild('warningToast') warningToast!: ElementRef;
  @ViewChild('dangerToast') dangerToast!: ElementRef;

  constructor(public toastService: ToastService) {}

  ngAfterViewInit(): void {
    this.toastService.successToastInstance = new (
      window as any
    ).bootstrap.Toast(this.successToast.nativeElement);
    this.toastService.infoToastInstance = new (window as any).bootstrap.Toast(
      this.infoToast.nativeElement
    );
    this.toastService.warningToastInstance = new (
      window as any
    ).bootstrap.Toast(this.warningToast.nativeElement);
    this.toastService.dangerToastInstance = new (window as any).bootstrap.Toast(
      this.dangerToast.nativeElement
    );
  }
}
