import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './shared/services/authentication.service';
import { MyCvTranslationService } from './shared/services/my-cv-translation.service';
import { NavigationService } from './shared/services/navigation.service';
import { UserIdleService } from './shared/services/user-idle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    // !! DO NOT REMOVE !!
    authenticationService: AuthenticationService,
    navigationService: NavigationService,
    myCvTranslationService: MyCvTranslationService,
    // !! DO NOT REMOVE !!
    private userIdleService: UserIdleService // private versionService: VersionService
  ) {}

  ngOnInit(): void {
    this.userIdleService.startWatchingIdle(3600); // 1 hour of inactivity
    // this.versionService.update();
  }
}
