import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  catchError,
  map,
  throwError,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { AccessTokenModel } from '../model/access-token.model';
import { AuthenticationModel } from '../model/authentication.model';
import { ForgetPasswordRequestModel } from '../model/forget-password-request.model';
import { Profile, ProfileModel } from '../model/profile.model';
import { ResetPasswordRequestModel } from '../model/reset-password-request.model';
import { SignupModel } from '../model/signup.model';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  public readonly authenticatedUser$: BehaviorSubject<ProfileModel | null> =
    new BehaviorSubject<ProfileModel | null>(null);

  private readonly api: string;
  private readonly authApi: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.api = `${environment.api}`;
    this.authApi = `${environment.auth_api}`;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public login(
    authentication: AuthenticationModel
  ): Observable<AccessTokenModel> {
    const observable: Observable<AccessTokenModel> = this.httpClient
      .post<AccessTokenModel>(
        `${this.authApi}/api/v1/auth/login`,
        authentication
      )
      .pipe(
        map((result: any) => {
          this.clearUserData();
          const accessToken: AccessTokenModel = Object.assign(
            new AccessTokenModel(),
            result
          );
          accessToken.remember = authentication.remember;
          accessToken.expires_at = new Date();
          if (!authentication.remember) {
            accessToken.expires_at.setDate(
              accessToken.expires_at.getDate() + 1
            );
          } else {
            accessToken.expires_at.setDate(
              accessToken.expires_at.getDate() + 30
            );
          }
          this.localStorageService.accessToken = accessToken;
          return accessToken;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
    return observable;
  }

  public signup(payload: SignupModel): Observable<AccessTokenModel> {
    const observable: Observable<AccessTokenModel> = this.httpClient
      .post<AccessTokenModel>(`${this.authApi}/api/v1/auth/signup`, payload)
      .pipe(
        map((result: any) => {
          this.clearUserData();
          const accessToken: AccessTokenModel = Object.assign(
            new AccessTokenModel(),
            result
          );
          accessToken.expires_at = new Date();
          accessToken.expires_at.setDate(accessToken.expires_at.getDate() + 1);
          this.localStorageService.accessToken = accessToken;
          return accessToken;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
    return observable;
  }

  public loadAuthenticatedUser() {
    return this.httpClient
      .get<ProfileModel>(`${this.authApi}/api/v1/auth/me`)
      .pipe(
        map((result: any) => Object.assign(new ProfileModel(), result)),
        map((user) => {
          this.authenticatedUser$.next(user);
          this.localStorageService.authUser = user;
          return user;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  public profile() {
    return this.httpClient.get<Profile>(`${this.authApi}/api/v1/auth/me`);
  }

  public hasAuthenticatedUser(): boolean {
    try {
      const helper = new JwtHelperService();
      const token = this.localStorageService.accessToken;
      if (!token) {
        return false;
      }
      if (helper.isTokenExpired(token.access_token)) {
        this.localStorageService.accessToken = null;
        return false;
      }
      if (!token.expires_at) {
        this.localStorageService.accessToken = null;
        return false;
      }
      const expires_at = new Date(token.expires_at);
      if (expires_at < new Date()) {
        this.localStorageService.accessToken = null;
        return false;
      }
      return true;
    } catch (e) {
      this.localStorageService.accessToken = null;
    }
    return false;
  }

  public hasAuthenticatedPdfUser(): boolean {
    try {
      const helper = new JwtHelperService();
      const token = this.localStorageService.pdfAccessToken;
      if (!!token) {
        return !helper.isTokenExpired(token.access_token);
      }
    } catch (e) {
      this.localStorageService.pdfAccessToken = null;
    }
    return false;
  }

  public getAccessToken(): AccessTokenModel {
    return this.localStorageService.accessToken;
  }

  public getPdfAccessToken(): AccessTokenModel {
    return this.localStorageService.pdfAccessToken;
  }

  public logout(): void {
    const subscription = this.httpClient
      .post<void>(`${this.api}/logout`, null)
      .subscribe(
        () => {
          this.clearUserDataAndRedirect();
        },
        (error) => {
          this.clearUserDataAndRedirect();
        }
      );
  }

  public clearUserDataAndRedirect(): void {
    this.clearUserData();
    this.router.navigate(['/log-in'], { relativeTo: this.activatedRoute });
  }

  public clearUserData(): void {
    this.localStorageService.clear();
  }

  public forgetPassword(
    forgetPasswordRequestModel: ForgetPasswordRequestModel
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${this.authApi}/api/v1/auth/reset-password`,
      forgetPasswordRequestModel
    );
  }

  public checkResetPassword(uuid: any) {
    return this.httpClient.get(
      `${this.authApi}/api/v1/auth/reset-password/${uuid}`
    );
  }

  public resetPassword(
    resetPasswordRequestModel: ResetPasswordRequestModel,
    uuid: any
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${this.authApi}/api/v1/auth/reset-password/${uuid}/complete`,
      resetPasswordRequestModel
    );
  }

  public shouldRememberUser(): boolean {
    return this.localStorageService.accessToken?.remember === true;
  }

  public async sha1(str: string) {
    const buffer = new TextEncoder().encode(str);
    const hash = await crypto.subtle.digest('SHA-1', buffer);
    const hexCodes = [];
    const view = new DataView(hash);
    for (let i = 0; i < view.byteLength; i += 1) {
      const byte = view.getUint8(i).toString(16).padStart(2, '0');
      hexCodes.push(byte);
    }
    return hexCodes.join('');
  }

  public userByUuid(uuid: string) {
    return this.httpClient.get(`${this.authApi}/api/v1/auth/users`, {
      params: { uuid },
    });
  }

  public addCompanyOwner(uuid: string, user_uuid: string) {
    return this.httpClient.post(
      `${this.authApi}/api/v1/companies/${uuid}/owners`,
      { user_uuid }
    );
  }

  public deleteCompanyOwner(uuid: string, user_uuid: string) {
    return this.httpClient.delete(
      `${this.authApi}/api/v1/companies/${uuid}/owners/${user_uuid}`,
      { body: { user_uuid } }
    );
  }

  public confirmAccount(user_uuid: string) {
    return this.httpClient.post(
      `${this.authApi}/api/v1/auth/confirm-account/${user_uuid}`,
      {}
    );
  }

  public confirmEmailChange(user_uuid: string) {
    return this.httpClient.post(
      `${this.authApi}/api/v1/auth/me/confirm-email-change`,
      {}
    );
  }
}
