import {Roles} from "./roles.enum";

export class AccessTokenModel {

  public access_token: string = "";
  public scopes: string[] = [];
  public remember: boolean = false;
  public expires_at?: Date;

  public hasJobSeekerRole(): boolean {
    return this.scopes.findIndex(value => value === Roles.JOB_SEEKER) === 0;
  }

  public hasRecruiterRole(): boolean {
    return this.scopes.findIndex(value => value === Roles.RECRUITER) === 0;
  }

}
