import { createReducer, on } from '@ngrx/store';
import { Profile } from 'src/app/shared/model/profile.model';
import { AuthActions } from '../actions/auth.actions';
import { environment } from 'src/environments/environment';

export interface AuthState {
  profile: Profile | null;
}

const initialState: AuthState = {
  profile: null,
};

export const authReducer = createReducer(
  initialState,
  on(
    AuthActions.loadProfileSuccess,
    (state, { payload }): AuthState => ({
      ...state,
      profile: {
        ...payload,
        company_roles: payload.company_roles || [],
        avatar_url: payload.avatar_url
          ? `${environment.avatar_base_url}/${payload.avatar_url}`
          : null,
      },
    })
  ),
  on(
    AuthActions.loadProfileFailure,
    (state): AuthState => ({ ...state, profile: null })
  )
);
