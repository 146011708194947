import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class PdfAuthorizationHeaderInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.authenticationService.hasAuthenticatedPdfUser()) {
      return next.handle(request);
    }
    const access_token =
      this.authenticationService.getPdfAccessToken()?.access_token;
    const clone = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${access_token}`),
    });
    return next.handle(clone);
  }
}
