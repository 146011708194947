import { CandidatesSearchSortEnum } from './candidates-search-sort.enum';
import { CandidatesSearchViewModeEnum } from './candidates-search-view-mode.enum';
import { GroupTeamsSearchSortEnum } from './group-teams-search-sort.enum';
import { MembersSearchSortEnum } from './members-search-sort.enum';
import { MyCvLanguageEnum } from './my-cv-language.enum';

export class PreferencesModel {
  public candidates_list_view_mode: string = 'card';
  public contacts_list_view_mode: string = 'card';
  public members_list_view_mode: string = 'card';
  public candidates_list_name_view_mode: string =
    CandidatesSearchViewModeEnum.FRIST_NAME;
  public contacts_list_name_view_mode: string =
    CandidatesSearchViewModeEnum.FRIST_NAME;
  public members_list_name_view_mode: string = 'user_full_name';
  public manage_group_teams_name_view_mode: string = 'first_name';
  public collapse_main_menu: boolean = false;
  public candidates_list_sort: string = CandidatesSearchSortEnum.NEWEST_ASC;
  public contacts_list_sort: string = CandidatesSearchSortEnum.NEWEST_ASC;
  public members_list_sort: string = MembersSearchSortEnum.NEWEST_ASC;
  public group_teams_list_sort: string =
    GroupTeamsSearchSortEnum.ALPHABETICAL_ASC;
  public selected_candidates_group: string = '';
  public selected_contacts_group: string = '';
  public skip_add_to_group_save_confirmation: boolean = false;
  public my_cv_language: string = MyCvLanguageEnum.EN;
}
