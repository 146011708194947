import {ActivatedRoute, CanActivate, Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {AuthenticationService} from "../services/authentication.service";

@Injectable()
export class LoginBypassGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  canActivate(): boolean {
    if(!this.authenticationService.hasAuthenticatedUser()){
      return true;
    }
    this.router.navigate(['app'], {relativeTo: this.activatedRoute});
    return false;
  }

}
