export enum SiteLanguage {

  EN = "en",
  NL = "nl"

}

export const SiteLanguages: { code: SiteLanguage, name: string }[] = [
  {code: SiteLanguage.EN, name: "English"},
  {code: SiteLanguage.NL, name: "Nederlands"},
]
