import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class IsAuthenticationGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.authenticationService.hasAuthenticatedUser()) {
      return true;
    }
    this.router.navigate(['log-in'], {
      relativeTo: this.activatedRoute,
      queryParams: { return: state.url },
    });
    return false;
  }
}
