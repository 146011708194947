export enum CandidatesSearchViewModeEnum {
  FRIST_NAME = 'first_name',
  LAST_NAME = 'last_name',
}

export const CandidateSearchViewModeOptions: {
  code: CandidatesSearchViewModeEnum;
  name: string;
}[] = [
  {
    code: CandidatesSearchViewModeEnum.FRIST_NAME,
    name: 'candidates.list.first-name-last-name',
  },
  {
    code: CandidatesSearchViewModeEnum.LAST_NAME,
    name: 'candidates.list.last-name-first-name',
  },
];
