<div class="position-fixed bottom-1 end-1 z-index-top">
  <div
    class="toast fade hide p-2 bg-white"
    role="alert"
    aria-live="assertive"
    #successToast
    aria-atomic="true"
  >
    <div class="toast-header border-0">
      <i class="material-icons text-success me-2"> check</i>
      <span class="me-auto font-weight-bold">
        {{ toastService.config.title }}
      </span>
      <i
        class="fas fa-times text-md ms-3 cursor-pointer"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></i>
    </div>
    <hr class="horizontal dark m-0" />
    <div class="toast-body">{{ toastService.config.text }}</div>
  </div>
  <div
    class="toast fade hide p-2 mt-2 bg-gradient-info"
    role="alert"
    aria-live="assertive"
    #infoToast
    aria-atomic="true"
    [attr.data-bs-autohide]="toastService.config.autoHide"
  >
    <div class="toast-header bg-transparent border-0">
      <i class="material-icons text-white me-2">notifications</i>
      <span class="me-auto text-white font-weight-bold">
        {{ toastService.config.title }}
      </span>
      <i
        class="fas fa-times text-md text-white ms-3 cursor-pointer"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></i>
    </div>
    <hr class="horizontal light m-0" />
    <div class="toast-body text-white">
      {{ toastService.config.text }}
    </div>
  </div>
  <div
    class="toast fade hide p-2 mt-2 bg-white"
    role="alert"
    aria-live="assertive"
    #warningToast
    aria-atomic="true"
  >
    <div class="toast-header border-0">
      <i class="material-icons text-warning me-2">travel_explore</i>
      <span class="me-auto font-weight-bold">
        {{ toastService.config.title }}
      </span>
      <i
        class="fas fa-times text-md ms-3 cursor-pointer"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></i>
    </div>
    <hr class="horizontal dark m-0" />
    <div class="toast-body">{{ toastService.config.text }}</div>
  </div>

  <div
    class="toast fade hide p-2 mt-2 bg-white"
    role="alert"
    aria-live="assertive"
    #dangerToast
    aria-atomic="true"
  >
    <div class="toast-header border-0">
      <i class="material-icons text-danger me-2">campaign</i>
      <span class="me-auto text-gradient text-danger font-weight-bold">
        {{ toastService.config.title }}
      </span>
      <i
        class="fas fa-times text-md ms-3 cursor-pointer"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></i>
    </div>
    <hr class="horizontal dark m-0" />
    <div class="toast-body">{{ toastService.config.text }}</div>
  </div>
</div>
