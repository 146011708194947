import { Injectable } from '@angular/core';

interface ToastConfig {
  title: string;
  text: string;
  autoHide?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public config: ToastConfig = { title: '', text: '', autoHide: true };

  private SuccessToastInstance: any;
  private InfoToastInstance: any;
  private WarningToastInstance: any;
  private DangerToastInstance: any;

  constructor() {}

  public set successToastInstance(instance: any) {
    this.SuccessToastInstance = instance;
  }

  public set infoToastInstance(instance: any) {
    this.InfoToastInstance = instance;
  }

  public set warningToastInstance(instance: any) {
    this.WarningToastInstance = instance;
  }

  public set dangerToastInstance(instance: any) {
    this.DangerToastInstance = instance;
  }

  public success(conf: ToastConfig) {
    this.config = { ...this.config, ...conf };
    this.SuccessToastInstance.show();
  }

  public info(conf: ToastConfig) {
    this.config = { ...this.config, ...conf };
    this.InfoToastInstance.show();
  }

  public infoClose() {
    this.config = { ...this.config, autoHide: true };
    this.InfoToastInstance.hide();
  }

  public warning(conf: ToastConfig) {
    this.config = { ...this.config, ...conf };
    this.WarningToastInstance.show();
  }

  public danger(conf: ToastConfig) {
    this.config = { ...this.config, ...conf };
    this.DangerToastInstance.show();
  }
}
