import { FirebaseOptions } from '@angular/fire/app';

const firebaseOptions: FirebaseOptions = {
  apiKey: 'AIzaSyASpCfy3h51tfQUZhh9LG-lX5-ZP9AVnV0',
  authDomain: 'itsmycv-test.firebaseapp.com',
  databaseURL:
    'https://itsmycv-test-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'itsmycv-test',
  appId: '1:531927007948:web:a175658d808695526cb869',
  measurementId: 'G-RTBY59WYZ8',
};

export const environment = {
  production: true,
  api: 'https://apis-test.itsmycv.com/api',
  candidates_api: 'https://apis-test.itsmycv.com/api',
  linkedin_scraper_api: 'https://apis-test.itsmycv.com/api/v1',
  pdf_printer_api: 'https://pdfmaker.itsmycv.com',
  teams_api: 'https://apis-test.itsmycv.com',
  auth_api: 'https://apis-test.itsmycv.com',
  metadata_api: 'https://apis-test.itsmycv.com',
  notification_api: 'https://apis-test.itsmycv.com',
  mycv_api: 'https://apis-test.itsmycv.com',
  avatar_base_url: 'https://users-avatar-test.itsmycv.com',
  version_timestamp: '20240706061724',
  firebase: firebaseOptions,
  sentry: {
    dsn: 'https://0c2631bfb8cdd56770b568fdc2d3cf0e@o4507293337976832.ingest.de.sentry.io/4507293370744912',
    tracePropagationTargets: ['phpstack-393666-2358298.cloudwaysapps.com'],
  },
};
