import { Inject, Injectable } from '@angular/core';
import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  StorageService,
} from 'ngx-webstorage-service';
import { AccessTokenModel } from '../model/access-token.model';
import { CountryModel } from '../model/country.model';
import { DepartmentModel } from '../model/department.model';
import { EmailTypeModel } from '../model/email-type.model';
import { IndustryModel } from '../model/industry.model';
import { PhoneTypeModel } from '../model/phone-type.model';
import { PositionLevelModel } from '../model/position-level.model';
import { PreferencesModel } from '../model/preferences.model';
import { SiteLanguage } from '../model/site-language.enum';
import { WorldLanguageModel } from '../model/world-language.model';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  readonly SITE_LANGUAGE_KEY = 'SITE_LANGUAGE';
  readonly ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
  readonly PREFERENCES_KEY = 'PREFERENCES';
  readonly COUNTRIES_KEY = 'COUNTRIES';
  readonly WORLD_LANGUAGES_KEY = 'WORLD_LANGUAGES';
  readonly PHONE_TYPES_KEY = 'PHONE_TYPES';
  readonly EMAIL_TYPES_KEY = 'EMAIL_TYPES';
  readonly INDUSTRIES_KEY = 'INDUSTRIES';
  readonly POSITION_LEVELS_KEY = 'POSITION_LEVELS';
  readonly DEPARTMENTS_KEY = 'DEPARTMENTS';
  readonly STATUSES_KEY = 'STATUSES';
  readonly INTERVIEW_TYPES_KEY = 'INTERVIEW_TYPES';
  readonly CANDIDATE_GROUPS_KEY = 'CANDIDATE_GROUPS';
  readonly CONTACT_GROUPS_KEY = 'CONTACT_GROUPS';
  readonly REMEMBER_USER_KEY = 'REMEMBER_USER';
  readonly ACCESS_TOKEN_KEY_PDF = 'TOKEN';
  readonly COMPANY_KEY = 'COMPANY';
  readonly AUTH_USER_KEY = 'AUTH_USER';

  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: StorageService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService
  ) {}

  public clear(): void {
    this.accessToken = null;
    this.pdfAccessToken = null;
    this.preferences = null;
    this.countries = null;
    this.worldLanguages = null;
    this.emailTypes = null;
    this.phoneTypes = null;
    this.industries = null;
    this.positionLevels = null;
    this.departments = null;
    this.statuses = null;
    this.interviewTypes = null;
    this.candidateGroups = null;
    this.contactGroups = null;
    this.company = null;
    this.authUser = null;
    this.sessionStorage.clear();
  }

  public get siteLanguage(): SiteLanguage {
    return this.localStorage.get(this.SITE_LANGUAGE_KEY);
  }

  public set siteLanguage(siteLanguage: SiteLanguage) {
    this.localStorage.set(this.SITE_LANGUAGE_KEY, siteLanguage);
  }

  public get accessToken(): AccessTokenModel {
    return this.localStorage.get(this.ACCESS_TOKEN_KEY);
  }

  public set accessToken(accessToken: AccessTokenModel | null) {
    this.localStorage.set(this.ACCESS_TOKEN_KEY, accessToken);
  }

  public get pdfAccessToken() {
    return this.localStorage.get(this.ACCESS_TOKEN_KEY_PDF);
  }

  public set pdfAccessToken(accessToken: any) {
    this.localStorage.set(this.ACCESS_TOKEN_KEY_PDF, accessToken);
  }

  public get preferences(): PreferencesModel {
    return this.localStorage.get(this.PREFERENCES_KEY);
  }

  public set preferences(preferences: PreferencesModel | null) {
    this.localStorage.set(this.PREFERENCES_KEY, preferences);
  }

  public get countries(): CountryModel[] {
    return this.localStorage.get(this.COUNTRIES_KEY);
  }

  public set countries(countries: CountryModel[] | [] | null) {
    this.localStorage.set(this.COUNTRIES_KEY, countries);
  }

  public get worldLanguages(): WorldLanguageModel[] {
    return this.localStorage.get(this.WORLD_LANGUAGES_KEY);
  }

  public set worldLanguages(languages: WorldLanguageModel[] | [] | null) {
    this.localStorage.set(this.WORLD_LANGUAGES_KEY, languages);
  }

  public get emailTypes(): EmailTypeModel[] {
    return this.localStorage.get(this.EMAIL_TYPES_KEY);
  }

  public set emailTypes(types: EmailTypeModel[] | [] | null) {
    this.localStorage.set(this.EMAIL_TYPES_KEY, types);
  }

  public get phoneTypes(): PhoneTypeModel[] {
    return this.localStorage.get(this.PHONE_TYPES_KEY);
  }

  public set phoneTypes(types: PhoneTypeModel[] | [] | null) {
    this.localStorage.set(this.PHONE_TYPES_KEY, types);
  }

  public get industries(): IndustryModel[] {
    return this.localStorage.get(this.INDUSTRIES_KEY);
  }

  public set industries(types: IndustryModel[] | [] | null) {
    this.localStorage.set(this.INDUSTRIES_KEY, types);
  }

  public get positionLevels(): PositionLevelModel[] {
    return this.localStorage.get(this.POSITION_LEVELS_KEY);
  }

  public set positionLevels(types: PositionLevelModel[] | [] | null) {
    this.localStorage.set(this.POSITION_LEVELS_KEY, types);
  }

  public get departments(): DepartmentModel[] {
    return this.localStorage.get(this.DEPARTMENTS_KEY);
  }

  public set departments(types: DepartmentModel[] | [] | null) {
    this.localStorage.set(this.DEPARTMENTS_KEY, types);
  }

  public get statuses(): any[] {
    return this.localStorage.get(this.STATUSES_KEY);
  }

  public set statuses(types: any[] | [] | null) {
    this.localStorage.set(this.STATUSES_KEY, types);
  }

  public get interviewTypes(): any[] {
    return this.localStorage.get(this.INTERVIEW_TYPES_KEY);
  }

  public set interviewTypes(types: any[] | [] | null) {
    this.localStorage.set(this.INTERVIEW_TYPES_KEY, types);
  }

  public get candidateGroups(): any[] {
    return this.localStorage.get(this.CANDIDATE_GROUPS_KEY);
  }

  public set candidateGroups(values: any[] | [] | null) {
    this.localStorage.set(this.CANDIDATE_GROUPS_KEY, values);
  }

  public get contactGroups(): any[] {
    return this.localStorage.get(this.CONTACT_GROUPS_KEY);
  }

  public set contactGroups(values: any[] | [] | null) {
    this.localStorage.set(this.CONTACT_GROUPS_KEY, values);
  }

  public set rememberUser(remember: boolean) {
    this.localStorage.set(this.REMEMBER_USER_KEY, remember);
  }

  public get rememberUser(): boolean {
    return this.localStorage.get(this.REMEMBER_USER_KEY) === true;
  }

  public set company(data: any) {
    this.localStorage.set(this.COMPANY_KEY, data);
  }

  public get company(): any | null {
    return this.localStorage.get(this.COMPANY_KEY);
  }

  public set authUser(data: any) {
    this.localStorage.set(this.AUTH_USER_KEY, data);
  }

  public get authUser(): any | null {
    return this.localStorage.get(this.AUTH_USER_KEY);
  }
}
