import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { PreferencesModel } from '../model/preferences.model';
import { CandidatesSearchSortEnum } from '../model/candidates-search-sort.enum';
import { MembersSearchSortEnum } from '../model/members-search-sort.enum';
import { GroupTeamsSearchSortEnum } from '../model/group-teams-search-sort.enum';
import { MyCvLanguageEnum } from '../model/my-cv-language.enum';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  public readonly candidatesListViewMode$: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.candidatesListViewMode);
  public readonly contactsListViewMode$: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.contactsListViewMode);
  public readonly membersListViewMode$: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.membersListViewMode);
  public readonly candidatesListNameViewMode$: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.candidatesListNameViewMode);
  public readonly contactsListNameViewMode$: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.contactsListNameViewMode);
  public readonly membersListNameViewMode$: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.membersListNameViewMode);
  public readonly manageGroupTeamsNameViewMode$: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.manageGroupTeamsNameViewMode);
  public readonly collapseMainMenu$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(this.mainMenuCollapsed);

  constructor(private localStorageService: LocalStorageService) {}

  get candidatesListViewMode(): string {
    if (!!this.localStorageService.preferences) {
      return this.localStorageService.preferences.candidates_list_view_mode;
    }
    return 'card';
  }

  get contactsListViewMode(): string {
    if (!!this.localStorageService.preferences) {
      return (
        this.localStorageService.preferences.contacts_list_view_mode || 'card'
      );
    }
    return 'card';
  }

  get membersListViewMode(): string {
    if (!!this.localStorageService.preferences) {
      return (
        this.localStorageService.preferences.members_list_view_mode || 'card'
      );
    }
    return 'card';
  }

  get candidatesListNameViewMode(): string {
    if (!!this.localStorageService.preferences) {
      return this.localStorageService.preferences
        .candidates_list_name_view_mode;
    }
    return 'first_name';
  }

  get contactsListNameViewMode(): string {
    if (!!this.localStorageService.preferences) {
      return this.localStorageService.preferences.contacts_list_name_view_mode;
    }
    return 'first_name';
  }

  get membersListNameViewMode(): string {
    if (!!this.localStorageService.preferences) {
      return this.localStorageService.preferences.members_list_name_view_mode;
    }
    return 'user_full_name';
  }

  get manageGroupTeamsNameViewMode(): string {
    if (!!this.localStorageService.preferences) {
      return this.localStorageService.preferences
        .manage_group_teams_name_view_mode;
    }
    return 'user_full_name';
  }

  get mainMenuCollapsed(): boolean {
    if (!!this.localStorageService.preferences) {
      return this.localStorageService.preferences.collapse_main_menu;
    }
    return false;
  }

  get candidateListSort(): any {
    if (!!this.localStorageService.preferences) {
      return this.localStorageService.preferences.candidates_list_sort;
    }
    return CandidatesSearchSortEnum.NEWEST_ASC;
  }

  get contactListSort(): any {
    if (!!this.localStorageService.preferences) {
      return this.localStorageService.preferences.contacts_list_sort;
    }
    return CandidatesSearchSortEnum.NEWEST_ASC;
  }

  get memberListSort(): any {
    if (!!this.localStorageService.preferences) {
      return this.localStorageService.preferences.members_list_sort;
    }
    return MembersSearchSortEnum.NEWEST_ASC;
  }

  get groupTeamsListSort(): any {
    if (!!this.localStorageService.preferences) {
      return this.localStorageService.preferences.group_teams_list_sort;
    }
    return GroupTeamsSearchSortEnum.ALPHABETICAL_ASC;
  }

  get myCvLanguage(): string {
    if (!!this.localStorageService.preferences) {
      return (
        this.localStorageService.preferences.my_cv_language ||
        MyCvLanguageEnum.EN
      );
    }
    return MyCvLanguageEnum.EN;
  }

  public changeCandidatesListViewMode(viewMode: string): void {
    this.candidatesListViewMode$.next(viewMode);
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.candidates_list_view_mode = viewMode;
    this.localStorageService.preferences = preferences;
  }

  public changeContactsListViewMode(viewMode: string): void {
    this.contactsListViewMode$.next(viewMode);
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.contacts_list_view_mode = viewMode;
    this.localStorageService.preferences = preferences;
  }

  public changeMembersListViewMode(viewMode: string): void {
    this.membersListViewMode$.next(viewMode);
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.members_list_view_mode = viewMode;
    this.localStorageService.preferences = preferences;
  }

  public changeCandidatesListNameViewMode(viewMode: string): void {
    this.candidatesListNameViewMode$.next(viewMode);
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.candidates_list_name_view_mode = viewMode;
    this.localStorageService.preferences = preferences;
  }

  public changeContactsListNameViewMode(viewMode: string): void {
    this.contactsListNameViewMode$.next(viewMode);
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.contacts_list_name_view_mode = viewMode;
    this.localStorageService.preferences = preferences;
  }

  public changeMembersListNameViewMode(viewMode: string): void {
    this.membersListNameViewMode$.next(viewMode);
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.members_list_name_view_mode = viewMode;
    this.localStorageService.preferences = preferences;
  }

  public changeManageGroupTeamsNameViewMode(viewMode: string): void {
    this.manageGroupTeamsNameViewMode$.next(viewMode);
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.manage_group_teams_name_view_mode = viewMode;
    this.localStorageService.preferences = preferences;
  }

  public collapseMainMenu(collapse: boolean): void {
    this.collapseMainMenu$.next(collapse);
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.collapse_main_menu = collapse;
    this.localStorageService.preferences = preferences;
  }

  public changeCandidateListSort(name: CandidatesSearchSortEnum): void {
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.candidates_list_sort = name;
    this.localStorageService.preferences = preferences;
  }

  public changeContactListSort(name: CandidatesSearchSortEnum): void {
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.contacts_list_sort = name;
    this.localStorageService.preferences = preferences;
  }

  public changeMemberListSort(name: MembersSearchSortEnum): void {
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.members_list_sort = name;
    this.localStorageService.preferences = preferences;
  }

  public changeGroupTeamsListSort(name: GroupTeamsSearchSortEnum): void {
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.group_teams_list_sort = name;
    this.localStorageService.preferences = preferences;
  }

  public get selectedCandidatesGroup(): string {
    if (!!this.localStorageService.preferences) {
      return this.localStorageService.preferences.selected_candidates_group;
    }
    return '';
  }

  public set selectedCandidatesGroup(group: string) {
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.selected_candidates_group = group;
    this.localStorageService.preferences = preferences;
  }

  public get selectedContactsGroup(): string {
    if (!!this.localStorageService.preferences) {
      return this.localStorageService.preferences.selected_contacts_group;
    }
    return '';
  }

  public set selectedContactsGroup(group: string) {
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.selected_contacts_group = group;
    this.localStorageService.preferences = preferences;
  }

  public get skipAddToGroupSaveConfirmation(): boolean {
    if (!!this.localStorageService.preferences) {
      return this.localStorageService.preferences
        .skip_add_to_group_save_confirmation;
    }
    return false;
  }

  public set skipAddToGroupSaveConfirmation(value: boolean) {
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.skip_add_to_group_save_confirmation = value;
    this.localStorageService.preferences = preferences;
  }

  public set changeMyCvLanguage(name: MyCvLanguageEnum) {
    let preferences = this.localStorageService.preferences;
    if (!preferences) {
      preferences = new PreferencesModel();
    }
    preferences.my_cv_language = name;
    this.localStorageService.preferences = preferences;
  }
}
