import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];
  private isBack = false;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.isBack) {
          this.isBack = false;
          return;
        }
        if (!this.history.length) {
          this.history.push(event.urlAfterRedirects);
          return;
        }
        if (
          this.history[this.history.length - 1].split('?')[0] !==
            event.urlAfterRedirects.split('?')[0] ||
          event.urlAfterRedirects.includes('tags=') ||
          event.urlAfterRedirects.includes('levels=') ||
          event.urlAfterRedirects.includes('departments=') ||
          event.urlAfterRedirects.includes('industries=') ||
          event.urlAfterRedirects.includes('statuses=') ||
          event.urlAfterRedirects.includes('lastContactedAfter=') ||
          event.urlAfterRedirects.includes('lastContactedBefore=') ||
          event.urlAfterRedirects.includes('search=') ||
          event.urlAfterRedirects.includes('withoutGroups=') ||
          event.urlAfterRedirects.includes('teams=') ||
          event.urlAfterRedirects.includes('permissions=') ||
          event.urlAfterRedirects.endsWith('/candidates') ||
          event.urlAfterRedirects.endsWith('/contacts') ||
          event.urlAfterRedirects.endsWith('/manage-team-members')
        ) {
          this.history.push(event.urlAfterRedirects);
        }
      }
    });
  }

  back(fallback: string = '/', useHistory = false, reset = false): void {
    this.isBack = true;
    this.history.pop();
    if (this.history.length > 0) {
      if (useHistory) {
        window.history.back();
      } else {
        this.router.navigateByUrl(this.history[this.history.length - 1]);
      }
    } else {
      this.router.navigateByUrl(fallback);
    }
    if (reset) {
      this.history = [];
    }
  }

  reset() {
    this.history = [];
  }
}
