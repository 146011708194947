import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyRole } from 'src/app/shared/model/company-role.enum';
import { AuthState } from '../reducers/auth.reducer';

export const selectAuth = createFeatureSelector<AuthState>('auth');

export const selectAuthProfile = createSelector(
  selectAuth,
  (state: AuthState) => state.profile
);

export const selectCompanySelected = createSelector(
  selectAuthProfile,
  (state) => state?.selected_company
);

export const selectIsCompanyVerified = createSelector(
  selectAuthProfile,
  (state) => state?.selected_company?.email_confirmed
);

export const selectIsCompanySuperAdmin = createSelector(
  selectAuthProfile,
  (state) => state?.company_roles.includes(CompanyRole.SUPER_ADMIN)
);

export const selectHasEditPermission = createSelector(
  selectAuthProfile,
  (state) =>
    state?.company_roles.includes(CompanyRole.SUPER_ADMIN) ||
    state?.company_roles.includes(CompanyRole.ADMIN) ||
    state?.company_roles.includes(CompanyRole.EDIT)
);
