import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthorizationErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.url.includes('/forget-password')) {
          return throwError(() => error);
        }
        if (request.url.includes('/reset-forgotten-password')) {
          return throwError(() => error);
        }
        if (request.url.includes('/auth/me/email')) {
          return next.handle(request);
        }
        if (request.url.includes('/change-password')) {
          return next.handle(request);
        }
        if (error.status === 401) {
          this.authenticationService.clearUserDataAndRedirect();
        }
        return throwError(() => error);
      })
    );
  }
}
