import { Company } from './company.model';

export class ProfileModel {
  public user_id: number = 0;
  public email: string = '';
  public email_confirmed: boolean = false;
  public first_name: string = '';
  public last_name: string = '';
  public picture: string = '';
  public selected_company_uuid: string = '';
  public uuid: string = '';
  public pending_email?: string = undefined;
  public company_roles?: string[] = [];

  get initials(): string {
    if (!!this.first_name) {
      const first = this.first_name.substring(0, 1);
      const last = this.last_name ? this.last_name.substring(0, 1) : '';
      return `${first}${last}`;
    }
    return '';
  }
}

export interface Profile {
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  pending_email: string;
  pending_email_request: boolean;
  active: boolean;
  email_confirmed: boolean;
  language: string;
  created_at: string;
  last_updated_at: string;
  deleted_at: string | null;
  change_email_requested_at: string | null;
  change_password_at: string | null;
  email_confirmed_at: string;
  selected_company_uuid?: string;
  selected_company?: Company;
  avatar_url: string | null;
  company_roles: string[];
  administrator: boolean;
  job_seeker: boolean;
  recruiter: boolean;
}
