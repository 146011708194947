import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { AuthenticationService } from '../shared/services/authentication.service';
import { AuthActions } from '../store/actions/auth.actions';

export function profileInitializer(
  store: Store,
  actions$: Actions,
  authenticationService: AuthenticationService
) {
  return () => {
    if (authenticationService.hasAuthenticatedUser()) {
      store.dispatch(AuthActions.loadProfile());

      return firstValueFrom(
        actions$.pipe(
          ofType(AuthActions.loadProfileSuccess, AuthActions.loadProfileFailure)
        )
      );
    } else {
      return Promise.resolve();
    }
  };
}
