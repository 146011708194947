import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Profile } from 'src/app/shared/model/profile.model';

export enum AuthActionTypes {
  LOAD_PROFILE = '[Auth] Load Profile',
  LOAD_PROFILE_SUCCESS = '[Auth] Load Profile Success',
  LOAD_PROFILE_FAILURE = '[Auth] Load Profile Failure',
}

const loadProfile = createAction(AuthActionTypes.LOAD_PROFILE);

const loadProfileSuccess = createAction(
  AuthActionTypes.LOAD_PROFILE_SUCCESS,
  props<{ payload: Profile }>()
);

const loadProfileFailure = createAction(
  AuthActionTypes.LOAD_PROFILE_FAILURE,
  props<{ payload: HttpErrorResponse }>()
);

export const AuthActions = {
  loadProfile,
  loadProfileSuccess,
  loadProfileFailure,
};
