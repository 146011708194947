export enum MembersSearchSortEnum {
  ALPHABETICAL_ASC = 'ALPHABETICAL_ASC',
  ALPHABETICAL_DESC = 'ALPHABETICAL_DESC',
  NEWEST_ASC = 'NEWEST_ASC',
  NEWEST_DESC = 'NEWEST_DESC',
}

export const MemberSearchSortOptions: {
  code: MembersSearchSortEnum;
  name: string;
}[] = [
  {
    code: MembersSearchSortEnum.NEWEST_ASC,
    name: 'manage-team-members.list.newest-top',
  },
  {
    code: MembersSearchSortEnum.NEWEST_DESC,
    name: 'manage-team-members.list.oldest-top',
  },
  {
    code: MembersSearchSortEnum.ALPHABETICAL_ASC,
    name: 'manage-team-members.list.name-a-to-z',
  },
  {
    code: MembersSearchSortEnum.ALPHABETICAL_DESC,
    name: 'manage-team-members.list.name-z-to-a',
  },
];
