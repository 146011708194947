import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {LocalStorageService} from "./local-storage.service";
import {SiteLanguage} from "../model/site-language.enum";

@Injectable({
  providedIn: 'root'
})
export class SiteLanguageService {

  public readonly siteLanguage$: BehaviorSubject<SiteLanguage> = new BehaviorSubject<SiteLanguage>(SiteLanguage.EN);
  private _browserLanguage: SiteLanguage | null = null;

  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService
  ) {
  }

  public init(): void {
    this.loadBrowserLanguage()
    this.changeLanguage(this.currentLanguage);
  }

  get browserLanguage(): SiteLanguage | null {
    return this._browserLanguage;
  }

  public get currentLanguage(): SiteLanguage {
    return this.localStorageService.siteLanguage || this.browserLanguage
  }

  public changeLanguage(siteLanguage: SiteLanguage): void {
    this.translateService.use(siteLanguage);
    this.localStorageService.siteLanguage = siteLanguage;
    this.siteLanguage$.next(siteLanguage);
  }

  private loadBrowserLanguage(): void {
    const browserLanguage = navigator.language || SiteLanguage.EN;
    if (browserLanguage.toLowerCase().includes(SiteLanguage.NL)) {
      this._browserLanguage = SiteLanguage.NL
      return
    }
    this._browserLanguage = SiteLanguage.EN;
  }

}
