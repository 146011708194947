import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeNl from '@angular/common/locales/nl';
import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  Provider,
  isDevMode,
} from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgIdleModule } from '@ng-idle/core';
import { Actions, EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { QuillModule } from 'ngx-quill';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { profileInitializer } from './initializer/profile.initializer';
import { AuthenticationService } from './shared/services/authentication.service';
import { SiteLanguageService } from './shared/services/site-language.service';
import { SharedModule } from './shared/shared.module';
import { reducers } from './store';
import { AuthEffects } from './store/effects/auth.effects';

registerLocaleData(localeNl, 'NL');
registerLocaleData(localeEn, 'EN');

let sentryProviders: Provider[] = [];

if (environment.sentry?.dsn) {
  sentryProviders = [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ];
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    QuillModule.forRoot(),
    NgIdleModule.forRoot(),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(AuthEffects),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
  ],
  providers: [
    ...sentryProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: profileInitializer,
      deps: [Store, Actions, AuthenticationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(siteLanguageService: SiteLanguageService) {
    siteLanguageService.init();
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    '/assets/i18n/',
    `.json?v=${Math.random()}`
  );
}
