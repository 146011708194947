import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

@Injectable({
  providedIn: 'root',
})
export class UserIdleService {
  constructor(
    private idle: Idle,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  startWatchingIdle(time: number): void {
    this.idle.setIdle(1); // Set the idle time in seconds
    this.idle.setTimeout(time); // Set the timeout time to zero to disable it
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onTimeout.subscribe(() => {
      window.location.href = this.router
        .createUrlTree(
          [this.activatedRoute.snapshot.queryParams['return'] || '/app'],
          { relativeTo: this.activatedRoute }
        )
        .toString();
    });

    this.idle.watch();
  }
}
