import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class SelectedCompanyHeaderInterceptor implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get('x-company-uuid')) {
      return next.handle(request);
    }
    if (this.localStorageService.company) {
      const paths = [
        '/templates',
        '/groups',
        '/statuses',
        '/interview-types',
        '/candidates',
        '/teams',
      ];
      if (paths.some((v) => request.url.includes(v))) {
        const uuid = this.localStorageService.company.uuid;
        const clone = request.clone({
          headers: request.headers.set('x-company-uuid', uuid),
        });
        return next.handle(clone);
      }
    }
    return next.handle(request);
  }
}
